import React from 'react';
import IntlTelInput, { IntlTelInputProps, CountryData } from 'react-intl-tel-input';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'
import 'react-intl-tel-input/dist/main.css';
import {
  COUNTRY_CODE,
  ISO2,
  VALID_NUMBER_LENGTH,
  VALID_NUMBER_REGEX
} from '../../constants/constants';

// Types
type CustomValidationFunction = (value: string, selectedCountryData: CountryData) => boolean;

interface CustomIntlTelInputProps extends IntlTelInputProps {
  customValidation?: CustomValidationFunction;
}

/**
 * Validates a mobile number.
 * This will allow us to override the default validation to make sure the number is valid even when marked as invalid sometimes.
 * 
 * @param {string} value - The phone number to validate.
 * @param {CountryData} selectedCountryData - The selected country data.
 * @returns {boolean} True if the number is valid, false otherwise.
 */
export const isMobileNumberValid = (value: string, selectedCountryData: CountryData): boolean => {

  // (default is South Africa validation unless overridden by environment variables)
  if (!value.startsWith(COUNTRY_CODE) || 
      selectedCountryData.iso2 !== ISO2 ||
      value.length !== VALID_NUMBER_LENGTH) {
    return false;
  }

  const phoneNumber = parsePhoneNumber(value, selectedCountryData.iso2 as CountryCode);
  if (phoneNumber) {
    return phoneNumber.isValid();
  }

  return VALID_NUMBER_REGEX.test(value.slice(1));
};

/**
 * A custom IntlTelInput component with additional validation capabilities.
 */
const CustomIntlTelInput: React.FC<CustomIntlTelInputProps> = ({ customValidation, onPhoneNumberChange, ...restProps }) => {
  const handlePhoneNumberChange = React.useCallback(
    (isValid: boolean, value: string, selectedCountryData: CountryData, fullNumber: string, extension: string) => {
      let customIsValid = isValid;

      if (!isValid && customValidation) { 
        customIsValid = customValidation(value, selectedCountryData);
      }

      onPhoneNumberChange?.(customIsValid, value, selectedCountryData, fullNumber, extension);
    },
    [customValidation, onPhoneNumberChange]
  );

  return (
    <IntlTelInput 
        {...restProps}
        preferredCountries={['za', 'gb', 'zw']}
        onPhoneNumberChange={handlePhoneNumberChange}
    />
  );
};

export default CustomIntlTelInput;